<body class="contact-page">
    <section class="contacts">
        <ul>
            <li>
                <article>
                    <div class="contacts-icons">
                        <i class="far fa-envelope fa-2x"></i>
                    </div>
                    <div class="contact-text">
                        <h3>Електронна поща</h3>
                        <p>foodorderapp@gmail.com</p>
                    </div>
                </article>
            </li>
            <li>
                <article>
                    <div class="contacts-icons">
                        <i class="fas fa-mobile-alt fa-2x"></i>
                    </div>
                    <div class="contact-text">
                        <h3>Телефон на управиел</h3>
                        <p>0999/ 99-99-99</p>
                    </div>
                </article>
            </li>

            <li>
                <article>
                    <div class="contacts-icons">
                        <i class="fas fa-map-marker-alt location"></i>
                    </div>
                    <div class="contact-text">
                        <h3>Адрес</h3>
                        <p>гр.София, жк Манастирски ливади 10Б</p>
                    </div>
                </article>
            </li>
        </ul>
    </section>
</body>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/constants/app.constants';
import { TokenStorageService } from 'src/app/_services/token/token-storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  private roles: string[];
  isLoggedIn = true;
  isLoggedInStream = this.tokenStorageService.isLoggedIn.subscribe(
    (res) => (this.isLoggedIn = res)
  );
  isAdmin = false;
  username: string;

  constructor(
    private tokenStorageService: TokenStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.isLoggedIn && this.tokenStorageService.getUser()) {
      this.router.navigate([AppConstants.PRODUCT_URL]);
      // const user = this.tokenStorageService.getUser();
      // this.roles? = user.roles;
      // this.username = user.displayName;
      this.isAdmin = this.roles?.includes('ROLE_ADMIN');
    }
  }
}

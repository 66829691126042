<body>
    <div *ngIf="products.length > 0 && products != null " class="bill">
        <h2>Сметка:  {{ fee }} лв</h2>
        <div>
            <ul>
                <li *ngFor="let product of products">
                    <div class="product-content">
                        <h3>{{ product.name }}</h3>
                        <p>{{ product.price}} лв</p>
                    </div>
                    <button class="btn btn-cancel" (click)="remove(product)">Премахни</button>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="products.length > 0" class="bill">
        <h2>Въведете адрес за доставка: *</h2>
        <div class="product-content">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <textarea formControlName="address" type="text" name="content" id="content" rows="3" cols="40"
                    class="height" required placeholder="улица, блок, №, етаж...">
                </textarea>
                <p class="error" *ngIf="form.get('address').touched && form.get('address').errors?.required">
                    Адресът е задължителен!
                </p>


                <div class="btn-order">
                    <p class="error" *ngIf="fee <= 5">
                        Поръчката, трябва да е повече от 5 лв!
                    </p>
                    <button class="btn btn-add" [disabled]="isLoading || form.invalid || fee <= 5">
                        Поръчай
                    </button>
                    <button class="btn btn-danger" (click)="onCancel()">Откажи</button>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="message" class="no-products">
        <p>{{ message }}</p>
    </div>

    <div *ngIf="products.length < 1 || products == null " class="no-products">
        <p>Няма избрани продукти.</p>
    </div>
</body>
и<body>
  <div class="card card-container">
    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <div class="form-group">
        <label for="displayName">Ново име</label> 
        <input type="text" class="form-control" name="displayName" [(ngModel)]="form.displayName" required #displayName="ngModel" />
        <div class="alert alert-danger" role="alert" *ngIf="f.submitted && displayName.invalid">Името е задължително!</div>
      </div>
      <div class="form-group">
        <label for="email">Нов имейл</label> 
        <input type="text" class="form-control" name="email" [(ngModel)]="form.email" required #email="ngModel" />
        <div class="alert alert-danger" role="alert" *ngIf="f.submitted && email.invalid">Имейлът е задължителен!</div>
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">{{ errorMessage }}</div>
      </div>
  
      <!-- Success message -->
      <div class="alert alert-success text-center font-weight-bold" role="alert" *ngIf="successMessage">{{ successMessage }}</div>
  
      <div class="form-group btn-position">
        <button [hidden]="showButton" class="btn btn-add">Промени</button>
      </div>
    </form>
  </div>
</body>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/constants/app.constants';
import { TokenStorageService } from 'src/app/_services/token/token-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  roles: string[];
  isLoggedInStream = this.tokenStorageService.isLoggedIn.subscribe(
    (res) => (this.isLoggedIn = res)
  );
  isLoggedIn: boolean;
  isAdmin = false;
  username: string;

  constructor(
    private tokenStorageService: TokenStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;
      this.isAdmin = this.roles?.includes('ROLE_ADMIN');
      this.username = user.displayName;
    }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.router.navigate([AppConstants.HOME_URL]);
  }
}

<body>
  <div class="card card-container">
    <img id="profile-img" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" class="profile-img-card" />
    <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <div class="form-group">
        <p class="content-divider center mt-3">
          <span>Вход</span>
        </p>
        <label for="username">Имейл</label> <input type="text" class="form-control" name="username"
          [(ngModel)]="form.username" required #username="ngModel" />
        <div class="alert alert-danger" role="alert" *ngIf="f.submitted && username.invalid">Имейлът е задължителен!
        </div>
      </div>
      <div class="form-group">
        <label for="password">Парола</label>
        <input type="password" class="form-control" name="password" [(ngModel)]="form.password" required minlength="5"
          #password="ngModel" />
        <div class="alert alert-danger" role="alert" *ngIf="f.submitted && password.invalid">
          <div *ngIf="password.errors.required">Паролата е задължителна!</div>
          <div *ngIf="password.errors.minlength">Паролата, трябва да е поне 5 символа!</div>
        </div>
      </div>
      <div class="form-group btn-position">
        <button class="btn btn-add">Влез</button>
      </div>
      <div class="form-group">
        <div class="alert alert-danger" role="alert" *ngIf="isLoginFailed">Неуспешен вход: Грешни данни !</div>
      </div>
      <div class="register">
        <p>
            Ако нямате профил може да се регистрирате
            <a href="http://85.187.246.13/user/signup">ТУК</a>.
        </p>
    </div>

    </form>
    <div class="alert alert-success welcome" *ngIf="isLoggedIn">Добре дошли {{currentUser.displayName}}</div>
  </div>
</body>

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppConstants } from 'src/app/constants/app.constants';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { TokenStorageService } from 'src/app/_services/token/token-storage.service';
import { UserService } from 'src/app/_services/user/user.service';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css'],
})
export class LogInComponent {
  form: any = {};
  isLoginFailed = false;
  isLoggedInStream = this.tokenStorageService.isLoggedIn.subscribe(
    (res) => (this.isLoggedIn = res)
  );
  isLoggedIn: boolean;
  errorMessage = '';
  currentUser = this.tokenStorage.getUser();
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  MENU = AppConstants.PRODUCT_URL;

  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private route: ActivatedRoute,
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const token: string = this.route.snapshot.queryParamMap.get('token');
    const error: string = this.route.snapshot.queryParamMap.get('error');
    // if (token) {
    //   this.tokenStorage.saveToken(token);
    //   this.userService.getCurrentUser().subscribe(
    //     (data) => {
    //       this.login(data);
    //     },
    //     (err) => {
    //       this.errorMessage = err.error.message;
    //       this.isLoginFailed = true;
    //     }
    //   );
    // } else if (error) {
    //   this.errorMessage = error;
    //   this.isLoginFailed = true;
    // }
    // if (this.tokenStorage.isLoggedIn) {
    //   this.router.navigate([AppConstants.HOME_URL]);
    // }
  }

  onSubmit(): void {
    this.authService.login(this.form).subscribe(
      (data) => {
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.isLoggedIn.next(true);
        this.tokenStorage.saveUser(data.user);
        this.redirectToHome();
      },
      (err) => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  redirectToHome(): void {
    this.router.navigate([AppConstants.HOME_URL]);
  }
}
